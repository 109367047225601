import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CPagination,
  CFormSelect,
} from "@coreui/react";
import { cilPencil, cilTrash, cilCloudDownload } from "@coreui/icons"; // Added download icon
import CIcon from "@coreui/icons-react";
import useApi from "../../../config/useApi"; // Assuming the useApi hook is reused
import CustomerModal from "./CustomerModal"; // Assuming you'll create a modal for editing customer
import { CSVLink } from "react-csv"; // Importing CSVLink component from react-csv
import DiscountCategoryModal from "./DiscountCategoryModal"; // Import the modal for discount category
import CustomerDetailsModal from "./CustomerDetails"; // Import the modal for showing customer details

const CustomerTable = () => {
  const { Get, Delete, getHost } = useApi();
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showDiscountModal, setShowDiscountModal] = useState(false); // State for showing discount modal
  const [selectedCustomerId, setSelectedCustomerId] = useState(null); // Selected customer ID for discount modal
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [customersPerPage, setCustomersPerPage] = useState(5);
  const [showDetailsModal, setShowDetailsModal] = useState(false); // New state for showing customer details modal
  const [customerDetails, setCustomerDetails] = useState(null); // State to store customer details
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  // Fetch customers
  const fetchCustomers = async () => {
    try {
      const data = await Get("customers");
      setCustomers(data.results); // Assuming the API returns an array under `results`
      setFilteredCustomers(data.results);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleDelete = async (id) => {
    try {
      await Delete("customers", id);
      fetchCustomers();
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
  };

  const handleEdit = (customer) => {
    setSelectedCustomer(customer);
    setShowModal(true);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered = customers.filter(
      (customer) =>
        customer.name.toLowerCase().includes(query.toLowerCase()) ||
        customer.address.toLowerCase().includes(query.toLowerCase()) ||
        customer.city.toLowerCase().includes(query.toLowerCase()) ||
        customer.state.toLowerCase().includes(query.toLowerCase()) ||
        customer.phone.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCustomers(filtered);
    setCurrentPage(1);
  };

  const handleCustomersPerPageChange = (e) => {
    setCustomersPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredCustomers.length / customersPerPage);
  const currentDataRangeStart = indexOfFirstCustomer + 1;
  const currentDataRangeEnd = Math.min(indexOfLastCustomer, filteredCustomers.length);

  // Prepare CSV Data
  const csvData = [
    ["Customer Name", "Address", "City", "State", "Phone"], // CSV header
    ...filteredCustomers.map((customer) => [
      customer.name,
      customer.address,
      customer.city,
      customer.state,
      customer.phone,
    ]),
  ];

  // Handle Discount Category Button click
  const handleSetDiscountCategory = (customerId) => {
    setSelectedCustomerId(customerId);  // Set the customer ID
    setShowDiscountModal(true);         // Show the DiscountCategoryModal
  };

  const handleCloseDiscountModal = () => {
    setShowDiscountModal(false);        // Close the modal
  };

  const handleRefresh = () => {
    fetchCustomers(); // Refresh the customer list after setting discount
  };

  const handleShowDetails = async (customerId) => {
    setIsLoadingDetails(true);
    setShowDetailsModal(true);

    try {
      const data = await Get(`${getHost()}/api/v1/customers/${customerId}/`);
      setCustomerDetails(data);
    } catch (error) {
      console.error("Error fetching customer details:", error);
      setCustomerDetails(null);
    } finally {
      setIsLoadingDetails(false);
    }
  };

  return (
    <div className="customer-table-container">
      <CCard>
        <CCardHeader>
          <h4>Customer Management</h4>
        </CCardHeader>

        <CCardBody>
          {/* Search, Add Customer, and Export Controls */}
          <div className="table-header">
            <CButton
              color="primary"
              onClick={() => {
                setSelectedCustomer(null);
                setShowModal(true);
              }}
              className="add-customer-btn"
            >
              Add Customer
            </CButton>

            {/* Set Discount Category Button */}
            <CButton
              color="info"
              onClick={() => handleSetDiscountCategory(1)} // Example customer ID
              className="set-discount-category-btn"
            >
              Set Discount Category
            </CButton>

            <CInputGroup className="search-box">
              <CInputGroupText>
                <span role="img" aria-label="search">
                  🔍
                </span>
              </CInputGroupText>
              <CFormInput
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search by customer name, address, city, state, or phone"
              />
            </CInputGroup>

            <CFormSelect
              aria-label="Items per page"
              value={customersPerPage}
              onChange={handleCustomersPerPageChange}
              className="items-per-page"
            >
              <option value={5}>5 per page</option>
              <option value={10}>10 per page</option>
              <option value={20}>20 per page</option>
              <option value={30}>30 per page</option>
            </CFormSelect>

            {/* CSV Export Button */}
            <CSVLink
              data={csvData}
              filename={"customers_data.csv"}
              className="btn btn-success export-btn"
              target="_blank"
            >
              <CIcon icon={cilCloudDownload} style={{ color: "white" }} size="lg" />{" "}
              <a style={{ color: "white" }}>Export</a>
            </CSVLink>
          </div>

          {/* Table Container */}
          <div className="table-responsive">
            <CTable striped hover responsive="sm" className="customer-table">
              <thead>
                <tr>
                  <CTableHeaderCell scope="col">No</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Customer Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Address</CTableHeaderCell>
                  <CTableHeaderCell scope="col">City</CTableHeaderCell>
                  <CTableHeaderCell scope="col">State</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Phone</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                </tr>
              </thead>
              <CTableBody>
                {currentCustomers.map((customer, index) => (
                  <CTableRow key={customer.id}>
                    <CTableDataCell>{indexOfFirstCustomer + index + 1}</CTableDataCell>
                    <CTableDataCell>{customer.name}</CTableDataCell>
                    <CTableDataCell>{customer.address}</CTableDataCell>
                    <CTableDataCell>{customer.city}</CTableDataCell>
                    <CTableDataCell>{customer.state}</CTableDataCell>
                    <CTableDataCell>{customer.phone}</CTableDataCell>
                    <CTableDataCell>
                      <CButton
                        color="warning"
                        onClick={() => handleEdit(customer)}
                        className="action-btn"
                      >
                        <CIcon icon={cilPencil} size="lg" />
                      </CButton>
                      <CButton
                        color="danger"
                        onClick={() => handleDelete(customer.id)}
                        className="action-btn"
                      >
                        <CIcon icon={cilTrash} size="lg" />
                      </CButton>
                      <CButton
                        color="info"
                        onClick={() => handleShowDetails(customer.id)} // Open details modal
                        className="action-btn"
                      >
                        Show Details
                      </CButton>
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </div>

          {/* Pagination */}
          <div className="pagination-container">
            <span>
              Showing {currentDataRangeStart} to {currentDataRangeEnd} of {filteredCustomers.length}
            </span>
          </div>

          <CPagination
            activePage={currentPage}
            pages={totalPages}
            onActivePageChange={paginate}
            className="pagination-bar"
            align="center"
          >
            {/* Previous Button */}
            <CButton
              color="secondary"
              disabled={currentPage === 1} // Disable if we're on the first page
              onClick={() => paginate(currentPage - 1)}
            >
              ◀
            </CButton>
            &nbsp;

            {[...Array(totalPages)].map((_, index) => (
              <CButton
                key={index}
                color={currentPage === index + 1 ? "primary" : "light"}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </CButton>
            ))}

            &nbsp;
            <CButton
              color="secondary"
              disabled={currentPage === totalPages} // Disable if we're on the last page
              onClick={() => paginate(currentPage + 1)}
            >
              ▶
            </CButton>
          </CPagination>
        </CCardBody>
      </CCard>

      {/* Customer Modal */}
      {showModal && (
        <CustomerModal
          show={showModal}
          onClose={() => setShowModal(false)}
          customer={selectedCustomer}
          onRefresh={fetchCustomers}
        />
      )}

      {/* Discount Category Modal */}
      {showDiscountModal && (
        <DiscountCategoryModal
          show={showDiscountModal}
          onClose={handleCloseDiscountModal}
          customerId={selectedCustomerId} // Pass the selected customer ID
          onRefresh={handleRefresh} // Refresh the customer list after setting discount
        />
      )}

<CustomerDetailsModal
      show={showDetailsModal} // This controls visibility
      onClose={() => {
        setShowDetailsModal(false);
        setCustomerDetails(null);
      }}
      customer={customerDetails}
    />
    </div>
  );
};

export default CustomerTable;
