import React from 'react';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CButton,
  CCard,
  CCardBody,
  CCardTitle,
  CBadge,
} from "@coreui/react";

const CustomerDetailsModal = ({ show, onClose, customer }) => {
  if (!customer) return null;

  return (
    <CModal
      visible={show}
      onClose={onClose}
      size="lg"
    >
      <CModalHeader closeButton>
        <CModalTitle>Customer Details</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {/* Basic Information Card */}
        <CCard className="mb-4">
          <CCardBody>
            <CCardTitle>Basic Information</CCardTitle>
            <div className="row mt-3">
              <div className="col-md-6 mb-2">
                <strong>Customer ID:</strong> {customer.id}
              </div>
              <div className="col-md-6 mb-2">
                <strong>Name:</strong> {customer.name}
              </div>
            </div>
          </CCardBody>
        </CCard>

        {/* Contact Information Card */}
        <CCard className="mb-4">
          <CCardBody>
            <CCardTitle>Contact Information</CCardTitle>
            <div className="row mt-3">
              <div className="col-12 mb-2">
                <strong>Address:</strong> {customer.address}
              </div>
              <div className="col-md-4 mb-2">
                <strong>City:</strong> {customer.city}
              </div>
              <div className="col-md-4 mb-2">
                <strong>State:</strong> {customer.state}
              </div>
              <div className="col-md-4 mb-2">
                <strong>Phone:</strong> {customer.phone}
              </div>
            </div>
          </CCardBody>
        </CCard>

        {/* Category Discounts Card */}
        {customer.category_discounts && customer.category_discounts.length > 0 && (
          <CCard>
            <CCardBody>
              <CCardTitle>Category Discounts</CCardTitle>
              <div className="mt-3">
                {customer.category_discounts.map((discount) => (
                  <div key={discount.id} className="border rounded p-3 mb-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <strong>Category:</strong> {discount.category_name}
                      </div>
                      <CBadge color="success" className="ms-2">
                        {discount.discount} %
                      </CBadge>
                    </div>
                    {discount.is_custom && (
                      <CBadge color="info" className="mt-2">
                        Custom Discount
                      </CBadge>
                    )}
                  </div>
                ))}
              </div>
            </CCardBody>
          </CCard>
        )}
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={onClose}
        >
          Close
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default CustomerDetailsModal;