import React from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CWidgetStatsA,
} from '@coreui/react';
import { getStyle } from '@coreui/utils';
import { CChartLine, CChartBar } from '@coreui/react-chartjs';
import { ArrowUpRight, ArrowDownRight, DollarSign, Package, Users, FileText } from 'lucide-react';
import useApi from '../../../config/useApi';

const ChallanDashboard = () => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [Get] = useApi();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Get("challans");
        setData(response.results);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateStats = () => {
    if (!data) return null;

    const totalInvoiceAmount = data.reduce((sum, challan) => sum + parseFloat(challan.invoice_amount), 0);
    const totalProducts = data.reduce((sum, challan) => sum + challan.products.length, 0);
    const uniqueCustomers = new Set(data.map(challan => challan.customer)).size;
    const averageAmount = totalInvoiceAmount / data.length;

    return {
      totalInvoiceAmount,
      totalProducts,
      uniqueCustomers,
      totalChallans: data.length,
      averageAmount
    };
  };

  const stats = calculateStats();

  if (loading) {
    return <div className="p-4">Loading...</div>;
  }

  return (
    <div className="p-4">
      <CRow>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4"
            color="primary"
            value={`₹${stats?.totalInvoiceAmount.toFixed(2)}`}
            title="Total Invoice Amount"
            icon={<DollarSign className="w-8 h-8" />}
            chart={
              <CChartLine
                className="mt-3 mx-3"
                style={{ height: '70px' }}
                data={{
                  labels: data?.slice(-7).map(item => item.challan_date),
                  datasets: [
                    {
                      label: 'Amount',
                      backgroundColor: 'transparent',
                      borderColor: 'rgba(255,255,255,.55)',
                      pointBackgroundColor: getStyle('--cui-primary'),
                      data: data?.slice(-7).map(item => parseFloat(item.invoice_amount))
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </CCol>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4"
            color="info"
            value={stats?.totalProducts.toString()}
            title="Total Products"
            icon={<Package className="w-8 h-8" />}
          />
        </CCol>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4"
            color="warning"
            value={stats?.uniqueCustomers.toString()}
            title="Unique Customers"
            icon={<Users className="w-8 h-8" />}
          />
        </CCol>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4"
            color="success"
            value={stats?.totalChallans.toString()}
            title="Total Challans"
            icon={<FileText className="w-8 h-8" />}
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm={6}>
          <CCard>
            <CCardHeader>
              <h4 className="mb-0">Recent Challans</h4>
            </CCardHeader>
            <CCardBody>
              <CChartBar
                data={{
                  labels: data?.slice(-5).map(item => item.challan_number),
                  datasets: [
                    {
                      label: 'Invoice Amount',
                      backgroundColor: 'rgba(51, 153, 255, 0.5)',
                      data: data?.slice(-5).map(item => parseFloat(item.invoice_amount)),
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm={6}>
          <CCard>
            <CCardHeader>
              <h4 className="mb-0">Customer Distribution</h4>
            </CCardHeader>
            <CCardBody>
              <div className="space-y-4">
                {data?.slice(-5).map((challan, index) => (
                  <div key={index} className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                      <p className="font-weight-bold mb-0">{challan.customer_name}</p>
                      <small className="text-muted">{challan.challan_number}</small>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="font-weight-bold">₹{parseFloat(challan.invoice_amount).toFixed(2)}</span>
                      {parseFloat(challan.difference_amount) > 0 ? (
                        <ArrowUpRight className="ms-2 text-success" size={16} />
                      ) : (
                        <ArrowDownRight className="ms-2 text-danger" size={16} />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default ChallanDashboard;