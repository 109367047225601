import React, { useEffect, useState } from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CForm,
  CFormInput,
  CFormSelect,
  CButton,
  CFormText,
} from "@coreui/react";
import useApi from "../../../config/useApi";

const ProductModal = ({ show, onClose, product, onRefresh, categories }) => {
  const { Post, Put } = useApi();

  // Initial state for form data
  const [formData, setFormData] = useState({
    name: "",
    product_price: "",
    category: "",
  });

  // Populate the form fields with the existing product data when editing
  useEffect(() => {
    if (product) {
      setFormData({
        name: product.name || "",
        product_price: product.product_price || "",
        category: product.category || "",
      });
    } else {
      setFormData({
        name: "",
        product_price: "",
        category: categories?.[0]?.id || "", // Default to the first category
      });
    }
  }, [product, categories]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submit (Add/Edit product)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { ...formData };

    try {
      if (product) {
        // Update existing product
        await Put("products", product.id, payload); // Using product ID to update
      } else {
        // Create new product
        await Post("products", payload); // Create a new product
      }
      onRefresh(); // Refresh product list
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error saving product:", error);
    }
  };

  return (
    <CModal visible={show} onClose={onClose}>
      <CModalHeader>
        <CModalTitle>{product ? "Edit Product" : "Add Product"}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm onSubmit={handleSubmit}>
          <CFormInput
            type="text"
            name="name"
            label="Product Name"
            value={formData.name}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }}
          />
          <CFormInput
            type="text"
            name="product_price"
            label="Product Price"
            value={formData.product_price}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }}
          />
          <CFormSelect
            name="category"
            label="Category"
            value={formData.category}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }}
          >
            {categories && categories.length > 0 ? (
              categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.category_name}
                </option>
              ))
            ) : (
              <option disabled>No categories available</option>
            )}
          </CFormSelect>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={onClose}>
          Cancel
        </CButton>
        <CButton color="primary" type="submit" onClick={handleSubmit}>
          {product ? "Save Changes" : "Add Product"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ProductModal;
