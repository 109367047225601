import React, { useState, useEffect } from 'react';
import { CCard, CCardBody, CCardHeader, CRow, CCol, CSpinner } from '@coreui/react';
import useApi from '../../../config/useApi';

export default function Home() {
  const [challansData, setChallansData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { Get } = useApi();

  // Function to fetch data from the API with pagination support
  const fetchAllData = async (url) => {
    let allData = [];
    let nextUrl = url;

    while (nextUrl) {
      try {
        const data = await Get(nextUrl);
        allData = [...allData, ...data.results]; // Add current page's results to the allData array
        nextUrl = data.next; // Get the next page URL
      } catch (error) {
        console.error('Error fetching data:', error);
        break; // If error occurs, stop fetching
      }
    }
    return allData;
  };

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show spinner while loading
      const allChallansData = await fetchAllData('challans'); // Start fetching from the 'challans' endpoint
      setChallansData(allChallansData); // Set the accumulated data in state
      setLoading(false); // Hide the spinner after data is loaded
    };

    fetchData();
  }, []);

  // If still loading, show a spinner
  if (loading) {
    return (
      <div className="text-center">
        <CSpinner color="primary" />
      </div>
    );
  }

  // Calculate the total Invoice Amount
  const totalInvoiceAmount = challansData.reduce((total, challan) => {
    return total + parseFloat(challan.invoice_amount || 0); // Make sure to handle undefined or null values
  }, 0).toFixed(2);

  // Calculate the total number of unique customers
  const customerNames = new Set(challansData.map(challan => challan.customer_name));
  const totalCustomers = customerNames.size;

  // Calculate the total number of challans
  const totalChallanNumber = challansData.length;

  return (
    <div className="container mt-4">
      <CRow>
        {/* Total Invoice Amount */}
        <CCol xs="12" md="4">
          <CCard>
            <CCardHeader>Total Invoice Amount</CCardHeader>
            <CCardBody style={{ padding: '2rem', backgroundColor: '#f5f8fa', borderRadius: '10px', textAlign: 'center' }}>
              <h3 style={{ color: '#4a90e2', fontWeight: 'bold' }}>₹{totalInvoiceAmount}</h3>
            </CCardBody>
          </CCard>
        </CCol>

        {/* Total Customers */}
        <CCol xs="12" md="4">
          <CCard>
            <CCardHeader>Total Customers</CCardHeader>
            <CCardBody style={{ padding: '2rem', backgroundColor: '#f5f8fa', borderRadius: '10px', textAlign: 'center' }}>
              <h3 style={{ color: '#7e8c8d', fontWeight: 'bold' }}>{totalCustomers}</h3>
            </CCardBody>
          </CCard>
        </CCol>

        {/* Total Challan Numbers */}
        <CCol xs="12" md="4">
          <CCard>
            <CCardHeader>Total Challan Numbers</CCardHeader>
            <CCardBody style={{ padding: '2rem', backgroundColor: '#f5f8fa', borderRadius: '10px', textAlign: 'center' }}>
              <h3 style={{ color: '#e67e22', fontWeight: 'bold' }}>{totalChallanNumber}</h3>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
}
