import React, { useState, useEffect } from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CForm,
  CFormInput,
  CButton,
  CFormSelect,
} from "@coreui/react";
import useApi from "../../../config/useApi";

const DiscountCategoryModal = ({ show, onClose, onRefresh }) => {
  const { Post, Get, getHost, getAPI } = useApi(); // Add Get method to fetch categories
  const [formData, setFormData] = useState({
    category_id: "",
    discount: "",
    customer_id: "", // Added customer_id to formData
  });
  const [categories, setCategories] = useState([]); // State to hold fetched categories
  const [customers, setCustomers] = useState([]); // State to hold fetched customers
  const [loading, setLoading] = useState(true); // Loading state for categories and customers

  // Fetch categories and customers on modal show
  useEffect(() => {
    if (show) {
      const fetchCategoriesAndCustomers = async () => {
        try {
          const [categoriesData, customersData] = await Promise.all([
            Get("categories"), // Fetch categories
            Get("customers"),  // Fetch customers
          ]);

          setCategories(categoriesData.results || []); // Assuming categories in `results`
          setCustomers(customersData.results || []); // Assuming customers in `results`
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };

      fetchCategoriesAndCustomers();
    }
  }, [show]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { category_id, discount, customer_id } = formData;

    if (!customer_id) {
      alert("Please select a customer");
      return;
    }

    const payload = {
      category_id,
      discount,
    };

    try {
      // Get the base API host
      const apiHost = getHost(); // Get the host from useApi hook

      // Post the data to set the discount category with the complete URL
      await Post(`${getHost()}/api/v1/customers/${customer_id}/set_category_discount/`, payload);

      // Refresh the customer list or handle other side effects
      onRefresh();
      onClose(); // Close the modal after submission
    } catch (error) {
      console.error("Error setting discount category:", error);
    }
  };

  return (
    <CModal visible={show} onClose={onClose}>
      <CModalHeader>
        <CModalTitle>Set Discount Category</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {loading ? (
          <p>Loading data...</p>
        ) : (
          <CForm onSubmit={handleSubmit}>
            {/* Customer Select */}
            <CFormSelect
              name="customer_id"
              label="Customer"
              value={formData.customer_id}
              onChange={handleInputChange}
              required
              style={{ marginBottom: "1rem" }}
            >
              <option value="">Select a Customer</option>
              {customers.length > 0 ? (
                customers.map((customer) => (
                  <option key={customer.id} value={customer.id}>
                    {customer.name} 
                  </option>
                ))
              ) : (
                <option disabled>No customers available</option>
              )}
            </CFormSelect>

            {/* Category Select */}
            <CFormSelect
              name="category_id"
              label="Category"
              value={formData.category_id}
              onChange={handleInputChange}
              required
              style={{ marginBottom: "1rem" }}
            >
              <option value="">Select a Category</option>
              {categories.length > 0 ? (
                categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))
              ) : (
                <option disabled>No categories available</option>
              )}
            </CFormSelect>

            {/* Discount Input */}
            <CFormInput
              type="number"
              name="discount"
              label="Discount (%)"
              value={formData.discount}
              onChange={handleInputChange}
              required
              min="0"
              max="100"
              style={{ marginBottom: "1rem" }}
            />
          </CForm>
        )}
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={onClose}>
          Cancel
        </CButton>
        <CButton color="primary" type="submit" onClick={handleSubmit}>
          Set Discount
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default DiscountCategoryModal;
