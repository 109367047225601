import React, { useEffect, useState } from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CForm,
  CFormInput,
  CFormCheck,
  CButton,
  CFormSelect,
} from "@coreui/react";
import useApi from "../../../config/useApi";

const UserModal = ({ show, onClose, user, onRefresh, availableGroups = [] }) => {
  const { Post, Put } = useApi();

  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    is_staff: false,
    is_active: false,
    groups: [], // Default empty array for groups
  });

  // Update form data if the user prop changes
  useEffect(() => {
    if (user) {
      // Populate form with user data for editing
      setFormData({
        username: user.username || "",
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        email: user.email || "",
        password: "", // Do not pre-fill password for security reasons
        is_staff: user.is_staff || false,
        is_active: user.is_active || false,
        groups: user.groups || [], // If groups is part of the user object
      });
    } else {
      // Set default group (e.g., 'manager') for new user
      setFormData({
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        is_staff: false,
        is_active: false,
        groups: [availableGroups.find((group) => group.name === "manager")?.id || ""], // Set 'manager' as default
      });
    }
  }, [user, availableGroups]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle form submit (Add/Edit user)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = { ...formData };

    try {
      if (user) {
        // Update user
        await Put("users", user.id, payload); // Use 'username' to update
      } else {
        // Add new user
        await Post("users", payload); // Create new user
      }
      onRefresh();
      onClose();
    } catch (error) {
      console.error("Error saving user:", error);
    }
  };

  return (
    <CModal visible={show} onClose={onClose}>
      <CModalHeader>
        <CModalTitle>{user ? "Edit User" : "Add User"}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm onSubmit={handleSubmit}>
          <CFormInput
            type="text"
            name="username"
            label="Username"
            value={formData.username}
            onChange={handleInputChange}
            required={!user} // Username is required when adding a new user
            style={{ marginBottom: "1rem" }} // Inline style for margin
          />
          <CFormInput
            type="text"
            name="first_name"
            label="First Name"
            value={formData.first_name}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }} // Inline style for margin
          />
          <CFormInput
            type="text"
            name="last_name"
            label="Last Name"
            value={formData.last_name}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }} // Inline style for margin
          />
          <CFormInput
            type="email"
            name="email"
            label="Email"
            value={formData.email}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }} // Inline style for margin
          />
          <CFormInput
            type="password"
            name="password"
            label="Password"
            value={formData.password}
            onChange={handleInputChange}
            required={!user} // Password is required only when adding a user
            style={{ marginBottom: "1rem" }} // Inline style for margin
          />
          <div style={{ marginBottom: "1rem" }}>
            <CFormCheck
              type="checkbox"
              name="is_staff"
              label="Staff Status"
              checked={formData.is_staff}
              onChange={handleInputChange}
              style={{ marginBottom: "0.5rem" }}
            />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <CFormCheck
              type="checkbox"
              name="is_active"
              label="Active"
              checked={formData.is_active}
              onChange={handleInputChange}
              style={{ marginBottom: "0.5rem" }}
            />
          </div>

          {/* Groups selection */}
          <CFormSelect
            name="groups"
            label="Groups"
            value={formData.groups}
            onChange={handleInputChange}
            multiple
            required
            style={{ marginBottom: "1rem" }} // Inline style for margin
          >
            {Array.isArray(availableGroups) && availableGroups.length > 0 ? (
              availableGroups.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))
            ) : (
              <option disabled>No groups available</option>
            )}
          </CFormSelect>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={onClose}>
          Cancel
        </CButton>
        <CButton
          color="primary"
          type="submit"
          onClick={handleSubmit}
                          >
          {user ? "Save Changes" : "Add User"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default UserModal;
