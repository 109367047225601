import React from 'react'

const Home = React.lazy(() => import('../components/pages/Home/Home'))
const Challan = React.lazy(() => import('../components/pages/Invoice/Challan'))
const Customer = React.lazy(() => import('../components/pages/Customer/Customer'))
const Products = React.lazy(() => import('../components/pages/Products/Products'))
const Categories = React.lazy(() => import('../components/pages/Categories/Categories'))
const Users = React.lazy(() => import('../components/pages/Users/Users'))

// Updated routes with new entries based on _nav
const routes = [
  { path: '/home', name: '', element: Home },
  { path: '/challan', name: 'Challan', element: Challan },
  { path: '/customer', name: 'Customer', element: Customer },
  { path: '/product', name: 'Products', element: Products },
  { path: '/categories', name: 'Categories', element: Categories },
  { path: '/users', name: 'Users', element: Users },
]

export default routes;
