import React from 'react'
import { CFooter, CRow, CCol } from '@coreui/react'

const AppFooter = () => {
  return (
    <CFooter className="px-4">
      <CRow className="w-100">
        {/* First column (left) */}
        <CCol xs={12} sm={6} className="d-flex justify-content-center justify-content-sm-start mb-2 mb-sm-0">
          <div>
            <span className="me-1">Powered by</span>
            <a href="https://clouddevlopers.xyz/" target="_blank" rel="noopener noreferrer">
              Cloud developers
            </a>
          </div>
        </CCol>

        {/* Second column (right) */}
        <CCol xs={12} sm={6} className="d-flex justify-content-center justify-content-sm-end">
          <div>
            <span className="me-1">Developed by</span>
            <span>Raaj</span>
          </div>
        </CCol>
      </CRow>
    </CFooter>
  )
}

export default React.memo(AppFooter)
