import React, {useContext, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import logo from '../../assets/brand/logo.png'; // Adjust path to your logo

import AuthContext from "../../config/AuthContext";


const Login = () => {


  let { loginUser } = useContext(AuthContext);

  let { authToken } = useContext(AuthContext);
  let navigate = useNavigate();



  let checkLogedIn = () => {
    if (authToken) {
      navigate("/home");
    } else {
      console.error("Unable to check authToken");
      return false;
    }
  };


  useEffect(() => {
    checkLogedIn();
  // eslint-disable-next-line
  }, [authToken]);


  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8} lg={6} xl={6}>
            <CCardGroup className="d-flex flex-column flex-lg-row">
              <CCard className="text-white bg-primary py-5 mb-4 mb-lg-0 w-120">
                <CCardBody className="text-center">
                  <div>
                    {/* Render logo image */}
                    <img src={logo} alt="Logo" className="mb-4" style={{ maxWidth: '100px', borderRadius:"10px" }} />
                    <h2>Welcome Back, User!</h2>

                    <p>
                      Have any questions? Our team is here to assist you. Feel
                      free to reach out at any time.
                    </p>
                  </div>
                </CCardBody>
              </CCard>

              {/* Login Form Card */}
              <CCard className="p-4 w-100">
                <CCardBody>
                  <CForm onSubmit={loginUser}>
                    <h1>Login</h1>
                    <p className="text-body-secondary">
                      Sign In to your account
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                                    name="username"

                        placeholder="Username"
                        autoComplete="username"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                      name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton type="submit" color="primary" className="px-4 w-100">
                          Login
                        </CButton>
                      </CCol>
                      {/* <CCol xs={6} className="text-right">
                        <Link to="/register">
                          <CButton color="contained" className="px-0 w-100">
                            Register Now!
                          </CButton>
                        </Link>
                      </CCol> */}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        <footer
          style={{
            textAlign: "center",
            marginTop: "40px",
            fontSize: "0.9rem",
            color: "#888",
          }}
        >
          Powered by Cloud Developer & Developed by Raaj &copy; {new Date().getFullYear()}
        </footer>
      </CContainer>
    </div>
  );
};

export default Login;
