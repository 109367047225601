import Dashboard from "../components/pages/Dashboard/Dashboard";
import Login from "../components/pages/Login";
import Home from "../components/pages/Home/Home";  // assuming you have a Home component
import Customer from "../components/pages/Customer/Customer";  // assuming you have a Customer component
import Categories from "../components/pages/Categories/Categories";  // assuming you have a Categories component
import Challan from "../components/pages/Invoice/Challan";
import Products from "../components/pages/Products/Products";
import Users from "../components/pages/Users/Users";

const routes = [
  {
    path: "/home",
    component: Home,
    auth: true,
  },
  {
    path: "/",
    component: Home,
    auth: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    auth: true,
  },
  {
    path: "/login",
    component: Login,
    auth: false,
  },
  {
    path: "/challan",
    component: Challan,
    auth: true,
  }, {
    path: "/users",
    component: Users,
    auth: true,
  },
  {
    path: "/product",
    component: Products,
    auth: true,
  },
  {
    path: "/customer",
    component: Customer,
    auth: true,
  },
  {
    path: "/categories",
    component: Categories,
    auth: true,
  },
];

export const getRoutes = () => routes;
