import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CPagination,
  CFormSelect,
} from "@coreui/react";
import { cilPencil, cilTrash, cilCloudDownload } from "@coreui/icons"; // Added download icon
import CIcon from "@coreui/icons-react";
import useApi from "../../../config/useApi";
import UserModal from "./UsersModal";
import { CSVLink } from "react-csv"; // Importing CSVLink component from react-csv
import "./UserTable.css"; // Import CSS for styling

const UserTable = () => {
  const { Get, Delete } = useApi();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(5);
  const [availableGroups, setAvailableGroups] = useState([]);

  const renderActionButtons = (user) => {
    if (user.username === 'admin') {
      return null;
    }

    return (
      <>
        <CButton
          color="warning"
          onClick={() => handleEdit(user)}
          className="action-btn"
        >
          <CIcon icon={cilPencil} size="lg" />
        </CButton>
        <CButton
          color="danger"
          onClick={() => handleDelete(user.id)}
          className="action-btn"
        >
          <CIcon icon={cilTrash} size="lg" />
        </CButton>
      </>
    );
  };

  const fetchUsers = async () => {
    try {
      const data = await Get("users");
      setUsers(data.results);
      setFilteredUsers(data.results);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchGroups = async () => {
    try {
      const data = await Get("role");
      setAvailableGroups(data.results);
    } catch (error) {
      console.error("Error fetching groups:", error);
      setAvailableGroups([
        { id: "1", name: "manager" },
        { id: "2", name: "admin" },
      ]);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchGroups();
  }, []);

  const handleDelete = async (id) => {
    try {
      await Delete("users", id);
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered = users.filter(
      (user) =>
        user.username.toLowerCase().includes(query.toLowerCase()) ||
        `${user.first_name} ${user.last_name}`
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        user.email.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredUsers(filtered);
    setCurrentPage(1);
  };

  const handleUsersPerPageChange = (e) => {
    setUsersPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  const currentDataRangeStart = indexOfFirstUser + 1;
  const currentDataRangeEnd = Math.min(indexOfLastUser, filteredUsers.length);

  // Prepare CSV Data
  const csvData = [
    ["Username", "First Name", "Last Name", "Email", "Role"], // CSV header
    ...filteredUsers.map((user) => [
      user.username,
      user.first_name,
      user.last_name,
      user.email,
      user.role ? user.role.name : "N/A", // If role exists, show name, else "N/A"
    ]),
  ];

  return (
    <div className="user-table-container">
      <CCard>
        <CCardHeader>
          <h4>User Management</h4>
        </CCardHeader>

        <CCardBody>
          <div className="table-header">
            <CButton
              color="primary"
              onClick={() => {
                setSelectedUser(null);
                setShowModal(true);
              }}
              className="add-user-btn"
            >
              Add User
            </CButton>

            <CInputGroup className="search-box">
              <CInputGroupText>
                <span role="img" aria-label="search">
                  🔍
                </span>
              </CInputGroupText>
              <CFormInput
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search by username, name, or email"
              />
            </CInputGroup>

            <CFormSelect
              aria-label="Items per page"
              value={usersPerPage}
              onChange={handleUsersPerPageChange}
              className="items-per-page"
            >
              <option value={5}>5 per page</option>
              <option value={10}>10 per page</option>
              <option value={20}>20 per page</option>
              <option value={30}>30 per page</option>
            </CFormSelect>

            <CSVLink
              data={csvData}
              filename={"users_data.csv"}
              className="btn btn-success export-btn"
              target="_blank"
            >
              <CIcon
                icon={cilCloudDownload}
                style={{ color: "white" }}
                size="lg"
              />{" "}
              <a style={{ color: "white" }}>Export</a>
            </CSVLink>
          </div>
          <div className="table-responsive">
          <CTable striped hover responsive="sm" className="user-table">
            <thead>
              <tr>
                <CTableHeaderCell scope="col">No</CTableHeaderCell>
                <CTableHeaderCell scope="col">Username</CTableHeaderCell>
                <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
              </tr>
            </thead>
            <CTableBody>
              {currentUsers.map((user, index) => (
                <CTableRow key={user.username}>
                  <CTableDataCell>
                    {indexOfFirstUser + index + 1}
                  </CTableDataCell>
                  <CTableDataCell>{user.username}</CTableDataCell>
                  <CTableDataCell>
                    {user.first_name} {user.last_name}
                  </CTableDataCell>
                  <CTableDataCell>{user.email}</CTableDataCell>
                  <CTableDataCell>
                    {renderActionButtons(user)}
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </div>

          <div className="pagination-container">
            <span>
              Showing {currentDataRangeStart} to {currentDataRangeEnd} of{" "}
              {filteredUsers.length}
            </span>
          </div>

          <CPagination
            activePage={currentPage}
            pages={totalPages}
            onActivePageChange={paginate}
            className="pagination-bar"
            align="center"
          >
            <CButton
              color="secondary"
              disabled={currentPage === 1} // Disable if we're on the first page
              onClick={() => paginate(currentPage - 1)}
            >
              ◀
            </CButton>
            &nbsp;
            {[...Array(totalPages)].map((_, index) => (
              <CButton
                key={index}
                color={currentPage === index + 1 ? "primary" : "light"}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </CButton>
            ))}
            &nbsp;
            <CButton
              color="secondary"
              disabled={currentPage === totalPages} // Disable if we're on the last page
              onClick={() => paginate(currentPage + 1)}
            >
              ▶
            </CButton>
          </CPagination>
        </CCardBody>
      </CCard>

      {showModal && (
        <UserModal
          show={showModal}
          onClose={() => setShowModal(false)}
          user={selectedUser}
          onRefresh={fetchUsers}
          availableGroups={availableGroups}
        />
      )}
    </div>
  );
};

export default UserTable;
