import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilSpeedometer,
  cilCalculator,
  cilStar,
  cilPuzzle,
  cilList,
} from "@coreui/icons";
import { CNavItem } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Home",
    to: "/home",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Challan",
    to: "/challan",
    icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Customer",
    to: "/customer",
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Product",
    to: "/product",
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Categories",
    to: "/categories",
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,
  },
];

export default _nav;
