import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './config/AuthContext';
import { Provider } from 'react-redux';  // Import the Provider from react-redux
import RouteManager from './config/RouteManager';
import { Toaster } from 'react-hot-toast'; // Import Toaster from react-hot-toast
import store from './components/store'; // Your Redux store configuration

import "./scss/style.scss";
import "./scss/examples.scss";

const App = () => {
  return (
    <Provider store={store}>  {/* Wrap the application in Redux Provider */}
      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
        <AuthProvider>
          <RouteManager />
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
