import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";


import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CForm,
  CFormInput,
  CButton,
  CFormSelect,
  CSpinner,
  CCard,
  CCardBody,
} from "@coreui/react";
import useApi from "../../../config/useApi";

const EnhancedChallanModal = ({
  show,
  onClose,
  challan,
  customerDetails,
  onRefresh,
}) => {
  const { Post, Put, Get, getHost } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customerCategories, setCustomerCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [categorizedProducts, setCategorizedProducts] = useState({});
  const [openCategory, setOpenCategory] = useState(null);
  const [printData, setPrintData] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const isNewChallan = !challan;
  const [searchTerm, setSearchTerm] = useState("");
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const handleProductChange = (product) => {
    if (!openCategory || !categorizedProducts[openCategory]) {
      console.error("Invalid category selected.");
      return;
    }

    const category_id = categorizedProducts[openCategory]?.category_id;

    if (!category_id) {
      console.error("Category ID not found.");
      return;
    }

    const price = parseFloat(product.product_price) || 0;
    const discount = categorizedProducts[openCategory]?.discount || 0;
    const quantity = 1;
    const initialTotal = (price * quantity * (1 - discount / 100)).toFixed(2);

    const newProduct = {
      product_id: product.id,
      product_name: product.name || "",
      quantity: quantity,
      price: price,
      discount: discount,
      total: initialTotal,
      selected_category: category_id,
    };

    setFormData((prev) => {
      const updatedProducts = [...prev.products, newProduct];
      const newTotalAmount = updatedProducts.reduce((sum, prod) => {
        return sum + parseFloat(prod.total);
      }, 0);

      return {
        ...prev,
        products: updatedProducts,
        total_amount: newTotalAmount.toFixed(2),
        difference_amount: (
          newTotalAmount - parseFloat(prev.invoice_amount || 0)
        ).toFixed(2),
      };
    });

    setProductSearchTerm("");
    setIsProductDropdownOpen(false);
  };

  useEffect(() => {
    if (openCategory && categorizedProducts[openCategory]) {
      const filtered = categorizedProducts[openCategory].products.filter(
        (product) =>
          product.name.toLowerCase().includes(productSearchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  }, [productSearchTerm, openCategory, categorizedProducts]);

  const [formData, setFormData] = useState({
    customer: "",
    careof: "", // Add care of field
    challan_date: new Date().toISOString().split("T")[0],
    invoice_number: "",
    invoice_amount: "", // Changed to string
    difference_amount: "", // Changed to string
    narration: "",
    total_amount: "", // Changed to string
    products: [],
    challan_type: "outward",
    courier_name: "",
    freight_charges: "", // Changed to string
    tax_amount: "", // Changed to string
  });

  // Filter customers based on search term
  const filteredCustomers = customers.filter((customer) =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Validation function
  const validateForm = () => {
    if (!formData.customer) {
      toast.error("Please select a customer");
      return false;
    }
    if (!formData.challan_date) {
      toast.error("Please select a challan date");
      return false;
    }
    if (!formData.invoice_number) {
      toast.error("Please enter an invoice number");
      return false;
    }
    if (!formData.invoice_amount) {
      toast.error("Please enter an invoice amount");
      return false;
    }
    if (!formData.courier_name) {
      toast.error("Please enter a Courier Name or leave - ");
      return false;
    }
    if (!formData.challan_type) {
      toast.error("Please select a challan type");
      return false;
    }
    if (formData.products.length === 0) {
      toast.error("Please add at least one product");
      return false;
    }

    // Validate each product
    for (let i = 0; i < formData.products.length; i++) {
      const product = formData.products[i];
      if (!product.product_name) {
        toast.error(`Product ${i + 1}: Please enter a product name`);
        return false;
      }
      if (!product.quantity || product.quantity <= 0) {
        toast.error(`Product ${i + 1}: Please enter a valid quantity`);
        return false;
      }
      if (!product.price || product.price <= 0) {
        toast.error(`Product ${i + 1}: Please enter a valid price`);
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    fetchCustomerDetails(formData.customer).catch((err) => {
      console.error(
        "Error in customer details effect:",
        err?.message || "Unknown error"
      );
    });
  }, [formData.customer]);

  useEffect(() => {
    fetchCustomers();
    fetchProducts();

    if (challan && customerDetails) {
      console.log("Initializing form with challan:", challan); // Debug log
      initializeFormWithChallanData();
    }
    if (customerDetails) {
      initializeFormWithCustomerData();
    }
  }, [customerDetails, challan]);

  useEffect(() => {
    if (challan && customerDetails) {
      // Initialize the form with challan data
      setFormData({
        customer: challan.customer,
        careof: challan.careof || "", // Add care of field
        challan_date: challan.challan_date.split("T")[0],
        invoice_number: challan.invoice_number || "",
        invoice_amount: challan.invoice_amount.toString(), // Changed to string
        difference_amount: challan.difference_amount.toString(), // Changed to string
        narration: challan.narration || "",
        total_amount: challan.total_amount.toString(), // Changed to string
        tax_amount: challan.tax_amount.toString(), // Changed to string
        products: challan.products || [],
        challan_type: challan.challan_type ?? "inward",
        courier_name: challan.courier_name ?? "",
        freight_charges: (challan.freight_charges ?? 0).toString(), // Changed to string
      });
    }
  }, [challan, customerDetails]);

  const calculateCategoryTotals = () => {
    const categoryTotals = {};
    formData.products.forEach((product) => {
      const category = customerCategories.find(
        (cat) => cat.id === product.selected_category
      );
      const categoryName = category ? category.category_name : "Uncategorized";

      if (!categoryTotals[categoryName]) {
        categoryTotals[categoryName] = 0;
      }
      categoryTotals[categoryName] += parseFloat(product.total) || 0;
    });
    return categoryTotals;
  };

  const initializeFormWithChallanData = () => {
    setIsLoading(true);
    try {
      console.log("Initializing form with challan:", challan);

      // Initialize form data with challan details
      const formDataToSet = {
        customer: challan.customer,
        careof: challan.careof || "", // Add fallback empty string
        challan_date: challan.challan_date,
        invoice_number: challan.invoice_number,
        invoice_amount: challan.invoice_amount.toString(), // Changed to string
        difference_amount: challan.difference_amount.toString(), // Changed to string
        narration: challan.narration || "",
        total_amount: challan.total_amount.toString(), // Changed to string
        challan_type: challan.challan_type ?? "inward",
        courier_name: challan.courier_name ?? "",
        tax_amount: challan.tax_amount.toString(), // Changed to string
        freight_charges: (challan.freight_charges ?? 0).toString(), // Changed to string
        products: challan.products.map((product) => ({
          product_id: product.product_id,
          product_name: product.product_name,
          quantity: product.quantity,
          price: product.price,
          discount: product.discount,
          total: (
            product.quantity *
            product.price *
            (1 - product.discount / 100)
          ).toFixed(2),
          selected_category: product.selected_category,
        })),
      };

      console.log("Setting form data:", formDataToSet);
      setFormData(formDataToSet);

      // Set selected customer
      setSelectedCustomer(customerDetails);

      // Set customer categories if available
      if (customerDetails.category_discounts) {
        setCustomerCategories(customerDetails.category_discounts);
      }
    } catch (error) {
      console.error("Error initializing form data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // Group products by category when products or customer categories change
  useEffect(() => {
    if (products.length > 0 && customerCategories.length > 0) {
      organizeProductsByCategory();
    }
  }, [products, customerCategories]);

  const fetchProducts = async () => {
    try {
      const response = await Get("products");
      console.log(response); // Log the response here
      if (response && response?.results && Array.isArray(response?.results)) {
        setProducts(response.results);
        console.log(response.results);
      }
    } catch (error) {
      console.error(
        "Error fetching products:",
        error?.message || "Unknown error"
      );
      setProducts([]);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await Get("customers");

      if (response && response?.results && Array.isArray(response?.results)) {
        setCustomers(response.results);
      } else {
        console.error("Invalid or empty customers data:", response);
        setCustomers([]);
      }
    } catch (error) {
      console.error(
        "Error fetching customers:",
        error?.message || "Unknown error"
      );
      setCustomers([]);
    }
  };

  const fetchCustomerDetails = async (customerId) => {
    setIsLoading(true); // Set loading to true to indicate fetching data
    try {
      // Fetch customer details by ID
      const response = await Get(`${getHost()}/api/v1/customers/${customerId}`);

      console.log(response.category_discounts || []);

      if (response) {
        // Set selected customer and customer categories based on the response
        setSelectedCustomer(response); // Set the customer data
        setCustomerCategories(response.category_discounts || []); // Handle category discounts (if available)
      } else {
        console.error("Invalid customer data format");
      }
    } catch (error) {
      console.error(
        "Error fetching customer details:",
        error?.message || "Unknown error"
      );
    } finally {
      setIsLoading(false); // Set loading to false after the API call is complete
    }
  };

  const organizeProductsByCategory = () => {
    const organized = {};
    customerCategories.forEach((category) => {
      // Initialize category structure
      organized[category.category_name] = {
        category_id: category.id, // Store the category ID
        discount: category.discount,
        products: [],
    }});
    products.forEach((product) => {
      const categoryDiscount = customerCategories.find(
        (cat) => cat.category_name === product.category
      );

      if (!organized[product.category]) {
        organized[product.category] = {
          products: [],
          discount: categoryDiscount?.discount || 0,
        };
      }

      organized[product.category].products.push({
        ...product,
        discountedPrice: calculateDiscountedPrice(
          product.product_price,
          categoryDiscount?.discount || 0
        ),
      });
    });

    setCategorizedProducts(organized);
    console.log(organized);
  };

  // Update the calculateDiscountedPrice function to handle invalid inputs:
  const calculateDiscountedPrice = (price, discount) => {
    const numPrice = parseFloat(price) || 0;
    const numDiscount = parseFloat(discount) || 0;
    const discountAmount = (numPrice * numDiscount) / 100;
    return (numPrice - discountAmount).toFixed(2);
  };
  const handleCustomerChange = (customerId, customerName) => {
    setFormData((prev) => ({
      ...prev,
      customer: customerId,
      products: [],
    }));
    setSearchTerm(customerName);
    setIsCustomerDropdownOpen(false);
    // This will trigger the useEffect to fetch customer details
  };

  const initializeFormWithCustomerData = async () => {
    setIsLoading(true);
    try {
      // Set basic customer information
      setFormData((prev) => ({
        ...prev,
        customer: customerDetails.id,

        products:
          customerDetails.products?.map((product) => ({
            product_name: product.name,
            product_id: product.id,
            quantity: 1,
            price: product.price || 0,
            discount: product.discount || 0,
            total: product.price || 0,
          })) || [],
      }));

      // Calculate initial totals
      calculateTotals();
    } catch (error) {
      console.error("Error initializing form:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      const updatedForm = { ...prev };

      // Handle product-specific fields
      if (name.startsWith("products-")) {
        const [_, index, field] = name.split("-");
        const productIndex = parseInt(index);
        const updatedProducts = [...prev.products];

        if (updatedProducts[productIndex]) {
          // Update the specific field
          updatedProducts[productIndex] = {
            ...updatedProducts[productIndex],
            [field]: value,
          };

          // Recalculate total for this product
          const quantity =
            parseFloat(updatedProducts[productIndex].quantity) || 0;
          const price = parseFloat(updatedProducts[productIndex].price) || 0;
          const discount =
            parseFloat(updatedProducts[productIndex].discount) || 0;

          updatedProducts[productIndex].total = (
            quantity *
            price *
            (1 - discount / 100)
          ).toFixed(2);

          updatedForm.products = updatedProducts;

          // Calculate new total amount including freight charges
          const productTotal = updatedProducts.reduce((sum, product) => {
            return sum + (parseFloat(product.total) || 0);
          }, 0);

          const freightCharges = parseFloat(updatedForm.freight_charges) || 0;
          const totalWithFreight = productTotal + freightCharges;
          
          updatedForm.total_amount = totalWithFreight.toFixed(2);

          // Calculate difference amount
          const invoiceAmount = parseFloat(updatedForm.invoice_amount) || 0;
          updatedForm.difference_amount = (
            totalWithFreight - invoiceAmount
          ).toFixed(2);
        }
      } else {
        // Handle non-product fields
        updatedForm[name] = value;

        // Recalculate totals when freight charges change
          if (name === "tax_amount" || name === "freight_charges" || name === "invoice_amount") {
          const productTotal = updatedForm.products.reduce((sum, product) => {
            return sum + (parseFloat(product.total) || 0);
          }, 0);
          
          const freightCharges = parseFloat(updatedForm.freight_charges) || 0;
          const taxAmount = parseFloat(updatedForm.tax_amount) || 0;

          const totalWithFreight = productTotal + freightCharges + taxAmount ;

          updatedForm.total_amount = totalWithFreight.toFixed(2);

          const invoiceAmount = parseFloat(updatedForm.invoice_amount) || 0;
          updatedForm.difference_amount = (
            totalWithFreight - invoiceAmount
          ).toFixed(2);
        }
      }

      return updatedForm;
    });
  };

  const calculateTotals = () => {
    const productTotal = formData.products.reduce((sum, product) => {
      return sum + (parseFloat(product.total) || 0);
    }, 0);
  
    const freightCharges = parseFloat(formData.freight_charges) || 0;
    const invoiceAmount = parseFloat(formData.invoice_amount) || 0;
    const taxAmount = parseFloat(formData.tax_amount) || 0; // Ensure tax is included
  
    // Include tax amount in total amount calculation
    const totalWithFreightAndTax = productTotal + freightCharges + taxAmount;
  
    setFormData((prev) => ({
      ...prev,
      total_amount: totalWithFreightAndTax.toFixed(2),
      difference_amount: (totalWithFreightAndTax - invoiceAmount).toFixed(2),
    }));
  };
  
  useEffect(() => {
    if (formData.products.length > 0 || formData.freight_charges) {
      calculateTotals();
    }
  }, [formData.products, formData.freight_charges]);

  const handlePrint = async () => {
    if (!formData || !formData.challan_date || formData.products.length === 0) {
      alert("Form data is incomplete. Please fill all required fields.");
      return;
    }

    setIsPrinting(true);
    const printWindow = window.open("", "_blank");

    if (!printWindow) {
      alert("Please allow popups to print.");
      setIsPrinting(false);
      return;
    }

    const categoryTotals = calculateCategoryTotals();

    const printData = JSON.parse(localStorage.getItem("challanPrintData"));
    const cleanedPrintData = { ...printData };
    delete cleanedPrintData.someIntegerField; // Replace 'someIntegerField' with the actual field name
    
    // Ensure print data is available
    if (!printData) {
      alert("No print data found in localStorage.");
      setIsPrinting(false);
      return;
    }

    const printContent = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <style>
    body {
      font-family: Arial, sans-serif;
      max-width: 800px;
      margin: 0;
      padding: 10px;
      box-sizing: border-box;
      font-size: 12px;
    }

    .challan-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: bold;
    }

    .customer-care-container {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
    }

    .customer-details, .careof-details {
      border: 2px solid #000;
      border-radius: 10px;
      padding: 10px;
      width: 300px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px; 
      font-size: 12px;
    
    }

    th, td {
      border: 1px solid #000;
      padding: 8px;
      text-align: right;
       font-size: 12px;
    }
       


    th {
      background-color: #f2f2f2;
    }

   .combined-box {
  display: flex;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 2px 8px;  /* Reduce top/bottom padding */
  align-items: stretch;
  width: 65%;
  margin-top: 3px;  /* Reduce top margin */
}


    .divider {
      border: 1px solid #000;
      margin: 0 10px;
      display: block;
    }

    @media print {
      @page {
        size: A5;
        margin: 10mm;
      }
      body {
        padding: 0;
      }
     /* Style Summary Box */
.summary-box td {
  border-top: none !important;    /* Remove unnecessary top border */
  text-align: right;
}

/* Add Vertical Borders (Standing Lines) */
.box-side {
  border-left: 1px solid #000 !important;  /* Left standing line */
  border-right: 1px solid #000 !important; /* Right standing line */
}

.customer-details, .careof-details, .combined-box {
  border: 2px solid #000;
  border-radius: 10px;
  padding: 2px; /* Reduce padding further */
}

.customer-details h3, .careof-details h3, .combined-box h3 {
  margin: 3px 1px; /* Reduce heading margin */
  font-size: 14px; /* Reduce heading size if necessary */
}

.customer-details div, .careof-details div, .combined-box div {
  margin: 1; /* Remove margins */
  padding: 2px 0; /* Minimize padding */
  line-height: 1.2; /* Reduce line spacing */
}




    }
  </style>
</head>
<body>

  <div class="challan-details">
    <div>Challan No.: ${printData.challan_number}</div>
    <div class="I">${printData.challan_type}</div>
    <div>Challan Date: ${printData.challan_date}</div>
  </div>

  <div class="customer-care-container">
    <div class="customer-details">
      <h3>Customer Details:</h3>
      <div>Name: ${printData.customer_name || ""}</div>
      <div>Address: ${printData.customer_address || ""}</div>
      <div>Phone: ${printData.customer_phone || ""}</div>
    </div>
    <div class="careof-details">
      <h3>Care Of:</h3>
      <div>${printData.careof || " "}</div>
    </div>
  </div>

  <table>
    <thead>
      <tr>
        <th>Product Name</th>
        <th>Qtn.</th>
        <th>Value</th>
        <th>Discount</th>
        <th>Total</th>
      </tr>
    </thead>
  <tbody>
  ${printData.products.map(product => `
    <tr>
      <td>${product.product_name}</td>
      <td>${product.quantity}</td>
      <td>${product.price}</td>
      <td>${product.discount}</td>
      <td>${product.total}</td>
    </tr>
  `).join("")}

  <!-- Summary Box with Standing Lines -->
  <tr class="summary-box">
    <td colspan="3" style="border: none;"></td>
    <td class="box-side"><strong>Freight Charges</strong></td>
    <td class="box-side">${printData.freight_charges}</td>
  </tr>
  <tr class="summary-box">
    <td colspan="3" style="border: none;"></td>
    <td class="box-side"><strong>Tax Amount</strong></td>
    <td class="box-side">${printData.tax_amount}</td>
  </tr>
  <tr class="summary-box grand-total">
    <td colspan="3" style="border: none;"></td>
    <td class="box-side"><strong>Grand Total</strong></td>
    <td class="box-side"><strong>${printData.total_amount}</strong></td>
  </tr>
</tbody>


  </table>

  <div class="combined-box">
    <div>
      <h3>Invoice Details</h3>
      <div><strong>Invoice Date:</strong> ${printData.invoice_number}</div>
      <br>
      <div><strong>Invoice Amount:</strong> ${printData.invoice_amount}</div>
      <br>
      <div><strong>MISC Amount:</strong> ${printData.difference_amount}</div>
    </div>
    <div class="divider"></div><br>
    <div>
      <h3>Narration</h3>
      <p>${printData.narration || ""}</p><br>
      <div><strong>Courier Name:</strong> ${printData.courier_name}</div>
    </div>
  </div>

  <script>
    window.onload = () => window.print();
    window.onafterprint = () => window.close();
  </script>
</body>
</html>




`;

    // Write the generated HTML to the print window
    printWindow.document.write(printContent);
    printWindow.document.close();

    setIsPrinting(false);
  };

  const handleAddProduct = (product, categoryDiscount) => {
    if (!product) return;

    if (!openCategory || !categorizedProducts[openCategory]) {
      console.error("Invalid category selected.");
      return;
    }

    const category_id = categorizedProducts[openCategory]?.category_id;

    if (!category_id) {
      console.error("Category ID not found.");
      return;
    }

    // Calculate initial total correctly
    const price = parseFloat(product.product_price) || 0;
    const discount = categoryDiscount || 0;
    const quantity = 1;
    const initialTotal = (price * quantity * (1 - discount / 100)).toFixed(2);

    const newProduct = {
      product_id: product.id,
      product_name: product.name || "",
      quantity: quantity,
      price: price,
      discount: discount,
      total: initialTotal,
      selected_category: category_id,
    };

    // Add new product and recalculate totals
    setFormData((prev) => {
      const updatedProducts = [...prev.products, newProduct];
      const newTotalAmount = updatedProducts.reduce((sum, prod) => {
        return sum + parseFloat(prod.total);
      }, 0);

      return {
        ...prev,
        products: updatedProducts,
        total_amount: newTotalAmount.toFixed(2),
        difference_amount: (
          parseFloat(prev.invoice_amount || 0) - newTotalAmount
        ).toFixed(2),
      };
    });

    console.log("Added product:", newProduct); // Debug log
  };

  const toggleCategory = (category) => {
    if (category) {
      setOpenCategory(category);
    } else {
      setOpenCategory(null);
    }
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = formData.products.filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      products: updatedProducts,
    }));
    calculateTotals();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    setIsLoading(true);

    try {
      const payload = {
        ...formData,
        products: formData.products.map((product) => ({
          ...product,
          total: parseFloat(product.total || 0),
        })),
      };

      let response;
      if (challan) {
        response = await Put(`challans`, challan.id, payload);
      } else {
        response = await Post("challans", payload);
      }

      if (response?.data) {
        // Store challan data in localStorage
        localStorage.setItem(
          "challanPrintData",
          JSON.stringify({
            ...response.data,
            customerName:
              customers.find((c) => c.id === formData.customer)?.name || "",
            printDate: new Date().toLocaleString(),
          })
        );

        setPrintData(response.data);
        await handlePrint();
        onRefresh?.();
        // Remove the onClose() call here
      }
    } catch (error) {
      console.error("Error saving challan:", error?.message || "Unknown error");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <CModal visible={show} onClose={onClose}>
        <CModalBody className="text-center">
          <CSpinner />
          <p>Loading...</p>
        </CModalBody>
      </CModal>
    );
  }

  return (
    <CModal
      visible={show}
      onClose={onClose}
      size="lg"
      backdrop="static" // Add this prop to prevent closing on outside click
      keyboard={false} // Add this prop to prevent closing on Escape key
    >
      <CModalHeader>
        <CModalTitle>
          {isNewChallan ? "Create New Challan" : "Edit Challan"}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm onSubmit={handleSubmit}>
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="position-relative">
                <label className="form-label">Select Customer *</label>
                <CFormInput
                  type="text"
                  placeholder="Search and select customer..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setIsCustomerDropdownOpen(true);
                  }}
                  onFocus={() => setIsCustomerDropdownOpen(true)}
                  required
                  className="mb-3"
                />
                {isCustomerDropdownOpen && filteredCustomers.length > 0 && (
                  <div
                    className="position-absolute w-100 bg-white border rounded shadow-sm"
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      zIndex: 1000,
                    }}
                  >
                    {filteredCustomers.map((customer) => (
                      <div
                        key={customer.id}
                        className="p-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          handleCustomerChange(customer.id, customer.name)
                        }
                        style={{ cursor: "pointer" }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f8f9fa")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.backgroundColor = "")
                        }
                      >
                        {customer.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <CFormInput
                type="text"
                name="careof"
                label="Care Of"
                value={formData.careof}
                onChange={handleInputChange}
                className="mb-3"
              />
            </div>

            <div className="col-md-6">
              {!isNewChallan && (
                <CFormInput
                  type="text"
                  label="Challan Number"
                  value={challan.challan_number}
                  disabled
                  className="mb-3"
                />
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <CFormInput
                type="date"
                name="challan_date"
                label="Challan Date"
                value={formData.challan_date}
                onChange={handleInputChange}
                required
                className="mb-3"
              />
            </div>
            <div className="col-md-6">
              <CFormSelect
                name="challan_type"
                label="Challan Type"
                value={formData.challan_type}
                onChange={handleInputChange}
                required
                className="mb-3"
              >
                <option value="outward">outward</option>
                <option value="inward">inward</option>
              </CFormSelect>
            </div>
            <div className="col-md-6">
              <CFormInput
                type="text"
                name="courier_name"
                label="Courier Name"
                value={formData.courier_name}
                onChange={handleInputChange}
                className="mb-3"
                min="1"
  step="1"
              />
            </div>
          </div>
          {Object.keys(categorizedProducts).length > 0 && (
            <div className="mb-4">
              <div className="row">
                <div className="col-md-6">
                  <CFormSelect
                    label="Select Category"
                    value={openCategory}
                    onChange={(e) => toggleCategory(e.target.value)}
                    className="mb-3"
                  >
                    <option value="">Select a Category</option>
                    {Object.keys(categorizedProducts).map((category) => (
                      <option key={category} value={category}>
                        {category}
                        {categorizedProducts[category].discount > 0 &&
                          ` (Discount: ${categorizedProducts[category].discount}%)`}
                      </option>
                    ))}
                  </CFormSelect>
                </div>
                <div className="col-md-6">
                  {openCategory && (
                    <div className="position-relative">
                      <label className="form-label">Select Product *</label>
                      <CFormInput
                        type="text"
                        placeholder="Search and select product..."
                        value={productSearchTerm}
                        onChange={(e) => {
                          setProductSearchTerm(e.target.value);
                          setIsProductDropdownOpen(true);
                        }}
                        onFocus={() => setIsProductDropdownOpen(true)}
                        className="mb-3"
                        min="1"
  step="1"
                      />
                      {isProductDropdownOpen && filteredProducts.length > 0 && (
                        <div
                          className="position-absolute w-100 bg-white border rounded shadow-sm"
                          style={{
                            maxHeight: "200px",
                            overflowY: "auto",
                            zIndex: 1000,
                          }}
                        >
                          {filteredProducts.map((product) => (
                            <div
                              key={product.id}
                              className="p-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleProductChange(product)}
                              style={{ cursor: "pointer" }}
                              onMouseOver={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "#f8f9fa")
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.style.backgroundColor = "")
                              }
                            >
                              {product.name} - ₹{product.product_price}
                              {categorizedProducts[openCategory].discount > 0 &&
                                ` (Discounted: ₹${product.discountedPrice})`}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="products-section mt-4">
            <h5>Selected Products</h5>
            {Array.isArray(formData.products) &&
              formData.products.map((product, index) => (
                <div
                  key={index}
                  className="product-row border p-3 mb-3 rounded"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <CFormInput
                        type="text"
                        name={`products-${index}-product_name`}
                        label="Product Name"
                        value={product.product_name || ""}
                        onChange={handleInputChange}
                        required
                        className="mb-2"
                        min="1"
  step="1"
                      />
                    </div>
                    <div className="col-md-6">
                      <CFormInput
                        type="number"
                        name={`products-${index}-quantity`}
                        label="Quantity"
                        value={product.quantity || ""}
                        onChange={handleInputChange}
                        required
                        min="1"
                        step="1"
                        className="mb-2"
                      />
                    </div>
                    <div className="col-md-4">
                      <CFormInput
                        type="number"
                        name={`products-${index}-price`}
                        label="Price"
                        value={product.price || ""}
                        onChange={handleInputChange}
                        required
                        min="1"
  step="1"
                        className="mb-2"
                      />
                    </div>
                    <div className="col-md-4">
                      <CFormInput
                        type="number"
                        name={`products-${index}-discount`}
                        label="Discount %"
                        value={product.discount || ""}
                        onChange={handleInputChange}
                        required
                        min="1"
  step="1"
                        max="100"
                        
                        className="mb-2"
                      />
                    </div>
                    <div className="col-md-4">
                      <CFormInput
                        type="text"
                        label="Total"
                        value={product.total || ""}
                        disabled
                        className="mb-2"
                        
                        min="1"
  step="1"
                      />
                    </div>
                  </div>
                  <CButton
                    color="danger"
                    size="sm"
                    onClick={() => handleRemoveProduct(index)}
                    className="mt-2"
                  >
                    Remove Product
                  </CButton>
                </div>
              ))}
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-md-12">
              <CCard>
                <CCardBody>
                  <h6 className="mb-3">Category-wise Totals</h6>
                  {Object.entries(calculateCategoryTotals()).map(
                    ([category, total]) => (
                      <div
                        key={category}
                        className="d-flex justify-content-between border-bottom py-2"
                      >
                        <span>{category}:</span>
                        <span>₹{total.toFixed(2)}</span>
                      </div>
                    )
                  )}
                </CCardBody>
              </CCard>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <CFormInput
                type="text"
                name="narration"
                label="Narration"
                value={formData.narration}
                onChange={handleInputChange}
                className="mb-3"
                min="1"
  step="1"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CFormInput
                type="text"
                name="invoice_number"
                label="Invoice Number"
                value={formData.invoice_number}
                onChange={handleInputChange}
                required
                className="mb-3"
                min="1"
  step="1"
              />
            </div>
            <div className="col-md-6">
              <CFormInput
                type="number"
                name="invoice_amount"
                label="Invoice Amount"
                value={formData.invoice_amount}
                onChange={handleInputChange}
                required
                className="mb-3"
                
  min="1"
  step="1"
              />
            </div>
          </div>
          {/* Total Amount and Difference */}
          <div className="row">
            <div className="col-md-6">
              <CFormInput
                type="text"
                name="total_amount"
                label="Total Amount"
                value={formData.total_amount}
                disabled
                step="1"
                className="mb-3"
                required
                min="1"
 
              />
            </div>

            <div className="col-md-4">
              <CFormInput
                type="number"
                name="freight_charges"
                label="Freight Charges"
                value={formData.freight_charges}
                onChange={handleInputChange}
                className="mb-3"
                required
  min="1"
  step="1"
              />
            </div>

            <div className="col-md-4">
              <CFormInput
                type="number"
                name="tax_amount"
                label="Tax Amount"
                value={formData.tax_amount}
                onChange={handleInputChange}
                className="mb-3"
                required
  min="1"
  step="1"
              />
            </div>
            <div className="col-md-6">
              <CFormInput
                type="number"
                name="difference_amount"
                label="Difference Amount"
                value={formData.difference_amount}
                onChange={handleInputChange}
                className="mb-3"
                required
  min="1"
  step="1"
              />
            </div>
          </div>
        </CForm>
      </CModalBody>

      {/* Modal Footer */}
      <CModalFooter>
        <CButton color="secondary" onClick={onClose}>
          Cancel
        </CButton>
        <CButton
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading || isPrinting}
        >
          {isLoading || isPrinting ? (
            <>
              <CSpinner size="sm" /> Processing...
            </>
          ) : isNewChallan ? (
            "Create Challan"
          ) : (
            "Update Challan"
          )}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default EnhancedChallanModal;
