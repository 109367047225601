import { useContext } from "react";
import React from "react";
import AuthContext from "./AuthContext";
import Login from "../components/pages/Login";
import { AppFooter, AppHeader, AppSidebar } from "../components/common/index";
import { CContainer } from "@coreui/react";

export const Auth = (props) => {
  let { user } = useContext(AuthContext);
  return props.check ? (
    user.user_id ? (
      <>
        <div className="d-flex">
          <AppSidebar />
          <div className="wrapper d-flex flex-column min-vh-100 w-100">
            <AppHeader />
            <div className="body flex-grow-1">
              <CContainer className="px-4" lg>
                {props.component}
              </CContainer>
            </div>
            <AppFooter />
          </div>
        </div>
      </>
    ) : (
      <Login />
    )
  ) : (
    props.component
  );
};

export default Auth;
