import React, { useEffect, useState } from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CForm,
  CFormInput,
  CFormButton,
  CButton,
  CFormText
} from "@coreui/react";
import useApi from "../../../config/useApi";

const CategoryModal = ({ show, onClose, category, onRefresh }) => {
  const { Post, Put } = useApi();
  const [formData, setFormData] = useState({
    category_name: "",
    description: "",
    discount: "",
  });

  // Update form data if the category prop changes
  useEffect(() => {
    if (category) {
      // Populate form with category data for editing
      setFormData({
        category_name: category.category_name || "",
        description: category.description || "",
        discount: category.discount || "",
      });
    } else {
      // Set default form data for new category
      setFormData({
        category_name: "",
        description: "",
        discount: "",
      });
    }
  }, [category]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submit (Add/Edit category)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = { ...formData };

    try {
      if (category) {
        // Update category
        await Put("categories", category.id, payload); // Use 'id' to update
      } else {
        // Add new category
        await Post("categories", payload); // Create new category
      }
      onRefresh(); // Refresh category list
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error saving category:", error);
    }
  };

  return (
    <CModal visible={show} onClose={onClose}>
      <CModalHeader>
        <CModalTitle>{category ? "Edit Category" : "Add Category"}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm onSubmit={handleSubmit}>
          <CFormInput
            type="text"
            name="category_name"
            label="Category Name"
            value={formData.category_name}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }} // Inline style for margin
          />
          <CFormInput
            type="text"
            name="description"
            label="Description"
            value={formData.description}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }} // Inline style for margin
          />
          <CFormInput
            type="text"
            name="discount"
            label="Discount"
            value={formData.discount}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }} // Inline style for margin
          />
          <CFormText>
            Discount should be a numeric value (e.g., 12.00).
          </CFormText>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={onClose}>
          Cancel
        </CButton>
        <CButton color="primary" type="submit" onClick={handleSubmit}>
          {category ? "Save Changes" : "Add Category"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default CategoryModal;
