import React, { useEffect, useState } from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CForm,
  CFormInput,
  CFormText,
  CButton,
  CFormSelect,
} from "@coreui/react";
import useApi from "../../../config/useApi";

const CustomerModal = ({ show, onClose, customer, onRefresh }) => {
  const { Post, Put } = useApi();

  // Initial state for form data
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    phone: "",
  });

  // Populate the form fields with the existing customer data when editing
  useEffect(() => {
    if (customer) {
      setFormData({
        name: customer.name || "",
        address: customer.address || "",
        city: customer.city || "",
        state: customer.state || "",
        phone: customer.phone || "",
      });
    } else {
      setFormData({
        name: "",
        address: "",
        city: "",
        state: "",
        phone: "",
      });
    }
  }, [customer]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submit (Add/Edit customer)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { ...formData };

    try {
      if (customer) {
        // Update existing customer
        await Put("customers", customer.id, payload); // Using customer ID to update
      } else {
        // Create new customer
        await Post("customers", payload); // Create a new customer
      }
      onRefresh(); // Refresh customer list
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error saving customer:", error);
    }
  };

  return (
    <CModal visible={show} onClose={onClose}>
      <CModalHeader>
        <CModalTitle>{customer ? "Edit Customer" : "Add Customer"}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm onSubmit={handleSubmit}>
          <CFormInput
            type="text"
            name="name"
            label="Customer Name"
            value={formData.name}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }}
          />
          <CFormInput
            type="text"
            name="address"
            label="Address"
            value={formData.address}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }}
          />
          <CFormInput
            type="text"
            name="city"
            label="City"
            value={formData.city}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }}
          />
          <CFormInput
            type="text"
            name="state"
            label="State"
            value={formData.state}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }}
          />
          <CFormInput
            type="text"
            name="phone"
            label="Phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
            style={{ marginBottom: "1rem" }}
          />
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={onClose}>
          Cancel
        </CButton>
        <CButton color="primary" type="submit" onClick={handleSubmit}>
          {customer ? "Save Changes" : "Add Customer"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default CustomerModal;
