import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CPagination,
  CFormSelect,
} from "@coreui/react";
import { cilPencil, cilTrash, cilCloudDownload } from "@coreui/icons"; // Added download icon
import CIcon from "@coreui/icons-react";
import useApi from "../../../config/useApi"; // Assuming the useApi hook is reused
import CategoryModal from "./CategoriesModal"; // Assuming you'll create a modal for editing category
import { CSVLink } from "react-csv"; // Importing CSVLink component from react-csv

const CategoryTable = () => {
  const { Get, Delete } = useApi();
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage, setCategoriesPerPage] = useState(5);

  const fetchCategories = async () => {
    try {
      const data = await Get("categories");
      setCategories(data.results); // Assuming the API returns an array under `results`
      setFilteredCategories(data.results);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleDelete = async (id) => {
    try {
      await Delete("categories", id);
      fetchCategories();
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleEdit = (category) => {
    setSelectedCategory(category);
    setShowModal(true);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered = categories.filter(
      (category) =>
        category.category_name.toLowerCase().includes(query.toLowerCase()) ||
        category.description.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCategories(filtered);
    setCurrentPage(1);
  };

  const handleCategoriesPerPageChange = (e) => {
    setCategoriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const indexOfLastCategory = currentPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategories = filteredCategories.slice(indexOfFirstCategory, indexOfLastCategory);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredCategories.length / categoriesPerPage);
  const currentDataRangeStart = indexOfFirstCategory + 1;
  const currentDataRangeEnd = Math.min(indexOfLastCategory, filteredCategories.length);

  // Prepare CSV Data
  const csvData = [
    ["Category Name", "Description", "Discount"], // CSV header
    ...filteredCategories.map((category) => [
      category.category_name,
      category.description,
      category.discount,
    ]),
  ];

  return (
    <div className="category-table-container">
      <CCard>
        <CCardHeader>
          <h4>Category Management</h4>
        </CCardHeader>

        <CCardBody>
          {/* Search, Add Category, and Export Controls */}
          <div className="table-header">
            <CButton
              color="primary"
              onClick={() => {
                setSelectedCategory(null);
                setShowModal(true);
              }}
              className="add-category-btn"
            >
              Add Category
            </CButton>

            <CInputGroup className="search-box">
              <CInputGroupText>
                <span role="img" aria-label="search">
                  🔍
                </span>
              </CInputGroupText>
              <CFormInput
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search by category name or description"
              />
            </CInputGroup>

            <CFormSelect
              aria-label="Items per page"
              value={categoriesPerPage}
              onChange={handleCategoriesPerPageChange}
              className="items-per-page"
            >
              <option value={5}>5 per page</option>
              <option value={10}>10 per page</option>
              <option value={20}>20 per page</option>
              <option value={30}>30 per page</option>
            </CFormSelect>

            {/* CSV Export Button */}
            <CSVLink
              data={csvData}
              filename={"categories_data.csv"}
              className="btn btn-success export-btn"
              target="_blank"
            >
              <CIcon icon={cilCloudDownload} style={{color:"white"}} size="lg" /> <a style={{color:"white"}}>Export</a>
            </CSVLink>
          </div>

          {/* Table Container */}
          <div className="table-responsive">
            <CTable striped hover responsive="sm" className="category-table">
              <thead>
                <tr>
                  <CTableHeaderCell scope="col">No</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Category Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Discount</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                </tr>
              </thead>
              <CTableBody>
                {currentCategories.map((category, index) => (
                  <CTableRow key={category.id}>
                    <CTableDataCell>{indexOfFirstCategory + index + 1}</CTableDataCell>
                    <CTableDataCell>{category.category_name}</CTableDataCell>
                    <CTableDataCell>{category.description}</CTableDataCell>
                    <CTableDataCell>{category.discount}</CTableDataCell>
                    <CTableDataCell>
                      <CButton
                        color="warning"
                        onClick={() => handleEdit(category)}
                        className="action-btn"
                      >
                        <CIcon icon={cilPencil} size="lg" />
                      </CButton>
                      <CButton
                        color="danger"
                        onClick={() => handleDelete(category.id)}
                        className="action-btn"
                      >
                        <CIcon icon={cilTrash} size="lg" />
                      </CButton>
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </div>

          {/* Pagination */}
          <div className="pagination-container">
            <span>
              Showing {currentDataRangeStart} to {currentDataRangeEnd} of {filteredCategories.length}
            </span>
          </div>

          <CPagination
            activePage={currentPage}
            pages={totalPages}
            onActivePageChange={paginate}
            className="pagination-bar"
            align="center"
          >
            {/* Previous Button */}
            <CButton
              color="secondary"
              disabled={currentPage === 1} // Disable if we're on the first page
              onClick={() => paginate(currentPage - 1)}
            >
              ◀
            </CButton>
            &nbsp;

            {[...Array(totalPages)].map((_, index) => (
              <CButton
                key={index}
                color={currentPage === index + 1 ? "primary" : "light"}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </CButton>
            ))}

            &nbsp;
            <CButton
              color="secondary"
              disabled={currentPage === totalPages} // Disable if we're on the last page
              onClick={() => paginate(currentPage + 1)}
            >
              ▶
            </CButton>
          </CPagination>
        </CCardBody>
      </CCard>

      {/* Category Modal */}
      {showModal && (
        <CategoryModal
          show={showModal}
          onClose={() => setShowModal(false)}
          category={selectedCategory}
          onRefresh={fetchCategories}
        />
      )}
    </div>
  );
};

export default CategoryTable;
