import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CPagination,
  CFormSelect,
} from "@coreui/react";
import { cilPencil, cilTrash, cilCloudDownload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import useApi from "../../../config/useApi";
import { CSVLink } from "react-csv";
import EnhancedChallanModal from "./ChallanDetailsModal"; // Updated import

const ChallanTable = () => {
  const { Get, Delete, getHost } = useApi();
  const [challans, setChallans] = useState([]);
  const [filteredChallans, setFilteredChallans] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedChallan, setSelectedChallan] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [challansPerPage, setChallansPerPage] = useState(5);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);

  const [pagination, setPagination] = useState({
    next: null,
    previous: null,
    total: 0
  });
  // Fetch Challans
  const fetchChallans = async (url = "challans") => {
    try {
      const data = await Get(url);
      setChallans(data.results);
      setFilteredChallans(data.results);
      setPagination({
        next: data.next,
        previous: data.previous,
        total: data.count
      });
    } catch (error) {
      console.error("Error fetching challans:", error);
    }
  };

  useEffect(() => {
    fetchChallans();
  }, []);


  const handleNextPage = () => {
    if (nextPage) {
      fetchChallans(nextPage); // Load the next page of data
    }
  };

  const handlePreviousPage = () => {
    if (previousPage) {
      fetchChallans(previousPage); // Load the previous page of data
    }
  };
  const handleDelete = async (event, id) => {
    event.preventDefault();
    if (!window.confirm("Are you sure you want to delete this challan?")) return;

    try {
      await Delete(`challans`, id);

      // Update both challans and filtered challans states
      const updatedChallans = challans.filter(challan => challan.id !== id);
      setChallans(updatedChallans);

      // Update filtered challans while maintaining search
      const updatedFiltered = filteredChallans.filter(challan => challan.id !== id);
      setFilteredChallans(updatedFiltered);

      // Adjust current page if necessary
      if (updatedFiltered.length === 0 && currentPage > 1) {
        setCurrentPage(prev => prev - 1);
      }

    } catch (error) {
      console.error("Error deleting challan:", error);
    }
  };


  const handleEdit = async (challan) => {
    setIsLoadingDetails(true);
    try {
      const challanDetails = await Get(`${getHost()}/api/v1/challans/${challan.id}/`);
      if (!challanDetails) throw new Error("Failed to fetch challan details");

      const formattedChallan = {
        id: challanDetails.id,
        careof : challanDetails.careof,
        challan_number: challanDetails.challan_number,
        challan_date: challanDetails.challan_date,
        customer: challanDetails.customer,
        invoice_number: challanDetails.invoice_number,
        invoice_amount: challanDetails.invoice_amount,
        difference_amount: challanDetails.difference_amount,
        total_amount: challanDetails.total_amount,
        narration: challanDetails.narration,
        tax_amount: challanDetails.tax_amount,
        challan_type: challan.challan_type,
        courier_name: challan.courier_name,
        freight_charges: challan.freight_charges,
        products: challanDetails.products.map(product => ({
          product_name: product.product_name,
          quantity: product.quantity,
          price: product.price,
          discount: product.discount,
          total: product.total,
          selected_category: product.selected_category,

        })),
      };

      setSelectedChallan(formattedChallan);
      setCustomerDetails({
        id: challanDetails.customer,
        name: challanDetails.customer_name,
        address: challanDetails.customer_address,
        phone: challanDetails.customer_phone,
        careof: challanDetails.careof
      });
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching challan details:", error);
      alert("Error loading challan details. Please try again.");
    } finally {
      setIsLoadingDetails(false);
    }
  };


  const handleModalClose = async (refreshData = false) => {
    setShowModal(false);
    setSelectedChallan(null);
    setCustomerDetails(null);

    if (refreshData) {
      await fetchChallans();
    }
  };


  const handleAddNew = () => {
    setSelectedChallan(null); // Clear selected challan
    setCustomerDetails(null); // Clear customer details
    setShowModal(true); // Show modal
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = challans.filter(
      (challan) =>
        challan.challan_number.toLowerCase().includes(query) ||
        challan.invoice_number.toLowerCase().includes(query) ||
        challan.customer.toString().includes(query) ||
        challan.challan_date.includes(query)
    );
    setFilteredChallans(filtered);
    setCurrentPage(1);
  };

  const handleChallansPerPageChange = (e) => {
    setChallansPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  // Pagination calculations
  const indexOfLastChallan = currentPage * challansPerPage;
  const indexOfFirstChallan = indexOfLastChallan - challansPerPage;
  const currentChallans = filteredChallans.slice(
    indexOfFirstChallan,
    indexOfLastChallan
  );
  const totalPages = Math.ceil(filteredChallans.length / challansPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Format currency values
  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);
  };

  // Prepare CSV Data with formatted values
  const csvData = [
    [
      "Challan Number",
      "Customer ID",
      "Challan Date",
      "Invoice Number",
      "Invoice Amount",
      "Total Amount",
    ],
    ...filteredChallans.map((challan) => [
      challan.challan_number,
      challan.customer,
      challan.challan_date,
      challan.invoice_number,
      formatCurrency(challan.invoice_amount),
      formatCurrency(challan.total_amount),
    ]),
  ];

  return (
    <div className="challan-table-container">
      <CCard>
        <CCardHeader>
          <h4>Challan Management</h4>
        </CCardHeader>

        <CCardBody>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start mb-4">
            <CButton color="primary" onClick={handleAddNew} className="mb-2 mb-md-0 me-md-2">
              Add New Challan
            </CButton>

            <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center w-100">
              <CInputGroup className="mb-2 mb-md-0 me-2" style={{ width: "100%" }}>
                <CInputGroupText>🔍</CInputGroupText>
                <CFormInput
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search challans..."
                />
              </CInputGroup>

              <CFormSelect
                value={challansPerPage}
                onChange={handleChallansPerPageChange}
                className="me-2"
                style={{ width: "100px" }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </CFormSelect>

              <CSVLink
                data={csvData}
                filename={"challans_export.csv"}
                className="btn btn-success mt-2 mt-md-0"
              >
                <CIcon icon={cilCloudDownload} className="me-1" />
                Export
              </CSVLink>
            </div>
          </div>

          <div className="table-responsive">
            <CTable striped hover>
              <thead>
                <tr>
                  <CTableHeaderCell>No</CTableHeaderCell>
                  <CTableHeaderCell>Challan Number</CTableHeaderCell>
                  <CTableHeaderCell>Customer Name</CTableHeaderCell>
                  <CTableHeaderCell>Challan Date</CTableHeaderCell>
                  <CTableHeaderCell>Invoice Number</CTableHeaderCell>
                  <CTableHeaderCell>Invoice Amount</CTableHeaderCell>
                  <CTableHeaderCell>Total Amount</CTableHeaderCell>
                  <CTableHeaderCell>Actions</CTableHeaderCell>
                </tr>
              </thead>
              <CTableBody>
                {currentChallans.map((challan, index) => (
                  <CTableRow key={challan.id}>
                    <CTableDataCell>
                      {indexOfFirstChallan + index + 1}
                    </CTableDataCell>
                    <CTableDataCell>{challan.challan_number}</CTableDataCell>
                    <CTableDataCell>{challan.customer_name}</CTableDataCell>
                    <CTableDataCell>
                      {new Date(challan.challan_date).toLocaleDateString()}
                    </CTableDataCell>
                    <CTableDataCell>{challan.invoice_number}</CTableDataCell>
                    <CTableDataCell>
                      {formatCurrency(challan.invoice_amount)}
                    </CTableDataCell>
                    <CTableDataCell>
                      {formatCurrency(challan.total_amount)}
                    </CTableDataCell>
                    <CTableDataCell>
                      <div className="d-flex gap-2">
                        <CButton
                          color="warning"
                          size="sm"
                          onClick={() => handleEdit(challan)}
                        >
                          <CIcon icon={cilPencil} />
                        </CButton>
                        <CButton
                          color="danger"
                          size="sm"
                          onClick={(event) => handleDelete(event, challan.id)}
                          >
                          <CIcon icon={cilTrash} />
                        </CButton>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-3">
            <span>
              Showing {indexOfFirstChallan + 1} to{" "}
              {Math.min(indexOfLastChallan, filteredChallans.length)} of{" "}
              {filteredChallans.length} entries
            </span>

            <CPagination>
              <CButton
                color="secondary"
                onClick={handlePreviousPage}
                disabled={!previousPage}
              >
                Previous
              </CButton>

              {[
                ...Array(Math.ceil(filteredChallans.length / challansPerPage)),
              ].map((_, index) => (
                <CButton
                  key={index}
                  color={currentPage === index + 1 ? "primary" : "secondary"}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </CButton>
              ))}

              <CButton
                color="secondary"
                onClick={handleNextPage}
                disabled={!nextPage}
              >
                Next
              </CButton>
            </CPagination>
          </div>
        </CCardBody>
      </CCard>

      {/* Open modal for both edit and add new */}
      {showModal && (
        <EnhancedChallanModal
          show={showModal}
          onClose={() => {
            setShowModal(false);
            setSelectedChallan(null);
            setCustomerDetails(null);
          }}
          challan={selectedChallan}
          customerDetails={customerDetails}
          onRefresh={fetchChallans}
        />
      )}
    </div>
  );
};

export default ChallanTable;
